
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexBF8d24101vMeta } from "/opt/render/project/src/apps/hub/client/pages/[organizationSlug]/credits/index.vue?macro=true";
import { default as invitationsNzjwy1tBqVMeta } from "/opt/render/project/src/apps/hub/client/pages/[organizationSlug]/credits/invitations.vue?macro=true";
import { default as membersojRPJhvt8BMeta } from "/opt/render/project/src/apps/hub/client/pages/[organizationSlug]/credits/members.vue?macro=true";
import { default as creditsoLLWmLO7iYMeta } from "/opt/render/project/src/apps/hub/client/pages/[organizationSlug]/credits.vue?macro=true";
import { default as requestsVU6nxkJXYMMeta } from "/opt/render/project/src/apps/hub/client/pages/[organizationSlug]/requests.vue?macro=true";
import { default as satisfactionfRvD2okz3OMeta } from "/opt/render/project/src/apps/hub/client/pages/[organizationSlug]/satisfaction.vue?macro=true";
import { default as _91sessionId_93HicULlsqjvMeta } from "/opt/render/project/src/apps/hub/client/pages/[organizationSlug]/sessions/[sessionId].vue?macro=true";
import { default as indexjRghPCMPglMeta } from "/opt/render/project/src/apps/hub/client/pages/[organizationSlug]/sessions/index.vue?macro=true";
import { default as _91organizationSlug_93O1RlcN4TawMeta } from "/opt/render/project/src/apps/hub/client/pages/[organizationSlug].vue?macro=true";
import { default as reset_45passwordV2OlsGDZPRMeta } from "/opt/render/project/src/apps/hub/client/pages/account/reset-password.vue?macro=true";
import { default as callbackUKf9peKsp8Meta } from "/opt/render/project/src/apps/hub/client/pages/auth/callback.vue?macro=true";
import { default as loginTZ6WUXpz6TMeta } from "/opt/render/project/src/apps/hub/client/pages/auth/login.vue?macro=true";
import { default as verifyse4KjB4GDKMeta } from "/opt/render/project/src/packages/core/client/pages/auth/v1/verify.vue?macro=true";
import { default as index2LMo0JBwP6Meta } from "/opt/render/project/src/apps/hub/client/pages/index.vue?macro=true";
import { default as join5x4ckFgIXJMeta } from "/opt/render/project/src/apps/hub/client/pages/join.vue?macro=true";
export default [
  {
    name: "organizationSlug",
    path: "/:organizationSlug()",
    meta: _91organizationSlug_93O1RlcN4TawMeta || {},
    component: () => import("/opt/render/project/src/apps/hub/client/pages/[organizationSlug].vue"),
    children: [
  {
    name: creditsoLLWmLO7iYMeta?.name,
    path: "credits",
    meta: creditsoLLWmLO7iYMeta || {},
    component: () => import("/opt/render/project/src/apps/hub/client/pages/[organizationSlug]/credits.vue"),
    children: [
  {
    name: "organizationSlug-credits",
    path: "",
    meta: indexBF8d24101vMeta || {},
    component: () => import("/opt/render/project/src/apps/hub/client/pages/[organizationSlug]/credits/index.vue")
  },
  {
    name: "organizationSlug-credits-invitations",
    path: "invitations",
    component: () => import("/opt/render/project/src/apps/hub/client/pages/[organizationSlug]/credits/invitations.vue")
  },
  {
    name: "organizationSlug-credits-members",
    path: "members",
    component: () => import("/opt/render/project/src/apps/hub/client/pages/[organizationSlug]/credits/members.vue")
  }
]
  },
  {
    name: "organizationSlug-requests",
    path: "requests",
    meta: requestsVU6nxkJXYMMeta || {},
    component: () => import("/opt/render/project/src/apps/hub/client/pages/[organizationSlug]/requests.vue")
  },
  {
    name: "organizationSlug-satisfaction",
    path: "satisfaction",
    meta: satisfactionfRvD2okz3OMeta || {},
    component: () => import("/opt/render/project/src/apps/hub/client/pages/[organizationSlug]/satisfaction.vue")
  },
  {
    name: "organizationSlug-sessions-sessionId",
    path: "sessions/:sessionId()",
    meta: _91sessionId_93HicULlsqjvMeta || {},
    component: () => import("/opt/render/project/src/apps/hub/client/pages/[organizationSlug]/sessions/[sessionId].vue")
  },
  {
    name: "organizationSlug-sessions",
    path: "sessions",
    meta: indexjRghPCMPglMeta || {},
    component: () => import("/opt/render/project/src/apps/hub/client/pages/[organizationSlug]/sessions/index.vue")
  }
]
  },
  {
    name: "account-reset-password",
    path: "/account/reset-password",
    meta: reset_45passwordV2OlsGDZPRMeta || {},
    component: () => import("/opt/render/project/src/apps/hub/client/pages/account/reset-password.vue")
  },
  {
    name: "auth-callback",
    path: "/auth/callback",
    meta: callbackUKf9peKsp8Meta || {},
    component: () => import("/opt/render/project/src/apps/hub/client/pages/auth/callback.vue")
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginTZ6WUXpz6TMeta || {},
    component: () => import("/opt/render/project/src/apps/hub/client/pages/auth/login.vue")
  },
  {
    name: "auth-v1-verify",
    path: "/auth/v1/verify",
    meta: verifyse4KjB4GDKMeta || {},
    component: () => import("/opt/render/project/src/packages/core/client/pages/auth/v1/verify.vue")
  },
  {
    name: "index",
    path: "/",
    meta: index2LMo0JBwP6Meta || {},
    component: () => import("/opt/render/project/src/apps/hub/client/pages/index.vue")
  },
  {
    name: "join",
    path: "/join",
    meta: join5x4ckFgIXJMeta || {},
    component: () => import("/opt/render/project/src/apps/hub/client/pages/join.vue")
  }
]